<template>
    <v-container fluid class="fill-height pa-0">

        <!-- Hauptauswahl: Teilnahme-Code vorhanden? -->
        <teilnahme-anfrage v-if="!regCodeAvailable && !verCode" @setAvailable="setRegCodeAvailable" />

        <v-row v-else class="justify-center">
            <v-col cols="12" sm="10" md="8" lg="6">
                 
                <!-- Eingabe Email, Passwort, Teilnahme-Code -->
                <v-form v-if="regCodeAvailable && !verCode && !show2FA" v-model="isValid" ref="regForm">
                    <v-card>
                        <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                            Ihre Registrierung
                        </v-card-title>
                        <v-card-text class="text-body-1 text--primary">
                            <v-row dense class="mt-3">
                                <v-col cols="12">
                                    <v-text-field label="Teilnahme-Code*" v-model="vBenutzerDaten.teilnahmeCode"
                                                  autocomplete="off" :rules="regCodeRules" :readonly="vBenutzerDaten.codeReadOnly"
                                                  v-mask="guidMask" placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                                                  @keyup="uppercase" :error-messages="regCodeErrors" counter="36"
                                                  validate-on-blur required dense />
                                </v-col>
                            </v-row>
                            <v-row dense class="mb-3">
                                <v-col cols="12" sm="3">
                                    <!-- Password forms should have (optionally hidden) username fields for accessibility -->
                                    <!-- Aktuell stimmt IK mit Username überein. Bei mehreren Benutzern evtl. korrigieren. -->
                                    <v-text-field label="IK" v-model="vBenutzerDaten.leistungserbringer"
                                                  autocomplete="username" disabled dense />
                                </v-col>
                                <v-col cols="12" sm="9">
                                    <v-text-field label="Apotheke" v-model="vBenutzerDaten.apoName"
                                                  autocomplete="off" disabled dense />
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-text-field label="E-Mail*" v-model="vBenutzerDaten.email"
                                                  autocomplete="email" :rules="emailRules" 
                                                  validate-on-blur clearable dense />
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-text-field label="Passwort*" v-model="vBenutzerDaten.pw"
                                                  autocomplete="new-password" :rules="passwordRules"                                                
                                                  :type="showPasswort ? 'text' : 'password'"
                                                  :append-icon="showPasswort ? 'mdi-eye-off' : 'mdi-eye'"
                                                  @click:append="() => (showPasswort = !showPasswort)" 
                                                  error-count="5" validate-on-blur dense clearable />
                                </v-col>
                            </v-row>
                            <v-row dense class="mb-3">
                                <v-col cols="12">
                                    <v-text-field label="Passwort bestätigen*" v-model="vBenutzerDaten.pwWiederholung"
                                                  autocomplete="new-password" :rules="repeatpasswordRules"
                                                  :type="pwTextRp ? 'password' : 'text'"
                                                  :append-icon="pwTextRp ? 'mdi-eye' : 'mdi-eye-off'"
                                                  @click:append="() => (pwTextRp = !pwTextRp)"
                                                  validate-on-blur dense clearable/>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="auto">
                                    <v-checkbox v-model="licenseAgreement4" class="mt-0 pt-0" hide-details />
                                </v-col>
                                <v-col>
                                    Modul Beanstandungsverfahren
                                    <p class="text-justify text-hyphenate">
                                        <router-link to="/termsbeanstandungen">
                                            Benutzungsbedingungen<br />
                                            Beanstandungsverfahren
                                        </router-link>
                                    </p>
                                </v-col>
                                <v-col cols="auto">
                                    <v-checkbox v-model="licenseAgreement5" class="mt-0 pt-0" hide-details />
                                </v-col>
                                <v-col>
                                    Modul Zuzahlungsprüfung/-rückforderung
                                    <p class="text-justify text-hyphenate">
                                        <router-link to="/termszuzahlung">
                                            Benutzungsbedingungen<br />
                                            Zuzahlungsprüfung/-rückforderung
                                        </router-link>
                                    </p>
                                </v-col>
                                <v-col>
                                    <p class="primary--text ">
                                        Bitte wählen Sie <strong>mindestens ein</strong> Modul aus.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="auto">
                                    <v-checkbox v-model="licenseAgreement1" class="mt-0 pt-0" hide-details />
                                </v-col>
                                <v-col>
                                    <p class="text-justify text-hyphenate">
                                        Ich bestätige, die Benutzungsbedingungen des GFS-Service-Portals
                                        gelesen zu haben und bin mit deren Geltung einverstanden.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="auto">
                                    <v-checkbox v-model="licenseAgreement2" class="mt-0 pt-0" hide-details />
                                </v-col>
                                <v-col>
                                    <p class="text-justify text-hyphenate">
                                        Ich bestätige, dass ich der <strong>Inhaber der teilnehmenden Apotheke</strong>
                                        ("Teilnehmer" im Sinne der <router-link to="/Termsallgemein">Benutzungsbedingungen</router-link>) bin, 
                                        die sich hiermit am GFS-Service-Portal registriert, oder den Inhaber im Verkehr mit 
                                        Krankenversicherungsträgern wirksam vertreten kann und darf.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="auto">
                                    <v-checkbox v-model="licenseAgreement3" class="mt-0 pt-0" hide-details />
                                </v-col>
                                <v-col>
                                    <p class="text-justify text-hyphenate">
                                        Ich <strong>verzichte</strong> hiermit gegenüber den jeweils am GFS-Service-Portal teilnehmenden
                                        Krankenversicherungsträgern ("Teilnehmenden KVTs") – diese vertreten durch GFS –, soweit die 
                                        Retax-Regelungen des für das Verhältnis zwischen uns und dem jeweiligen Teilnehmenden KVT maßgeblichen 
                                        Rahmen- und Versorgungsvertrages die Schriftlichkeit der Retax-Ankündigungen / Beanstandungen und/oder 
                                        des Prüfungsergebnisses vorsehen, <strong>auf dieses Schriftformerfordernis</strong> und werde dessen 
                                        Nichteinhaltung nicht gegenüber GFS oder den Teilnehmenden KVTs rügen. Dieser Verzicht ist unwiderruflich 
                                        für die Zeit der Teilnahme am GFS-Service-Portal und ich bestätige ausdrücklich, dass während dieser Zeit 
                                        der Informationsaustausch im Rahmen des Beanstandungsverfahrens über das GFS-Service-Portal die 
                                        Schriftform ersetzt (auch wenn eine schriftliche Übermittlung von Retax-Ankündigungen / Beanstandungen 
                                        und/oder von Prüfungsergebnissen durch GFS bzw. den Teilnehmenden KVT in Ausnahmefällen zulässig bleibt).
                                        Bei Retaxen, Einsprüchen und Entscheidungen erfolgt zusätzlich eine <strong>Benachrichtigung per E-Mail</strong>.
                                    </p>

                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row dense class="align-center justify-end mb-2 mx-1">
                                <v-col cols="auto" class="flex-grow-1">
                                    <v-alert v-if="!allLicensesAgreed" type="error"
                                             class="mb-0 text-center" dense outlined>

                                        Zur Registrierung müssen Sie allen Bedingungen zustimmen
                                    </v-alert>
                                </v-col>
                                <v-col cols="auto" class="d-flex flex-grow-1 justify-center justify-sm-end">
                                    <v-btn color="primary" type="submit" class="mr-1"
                                           :loading="isLoading" :disabled="!isInputValid || isLoading"
                                           @click.stop.prevent="show2FA = true">

                                        Weiter
                                    </v-btn>
                                    <v-btn color="primary" type="button" class="ml-1"
                                           @click.stop.prevent="regCodeAvailable = false">

                                        Zurück
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <!-- 2FA Authenticator Setup -->
                <v-form v-if="!verCode && show2FA">              
                    <v-card>
                        <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                            Authentifizierungs-App
                        </v-card-title>
                        <v-card-text class="text-body-1 text--primary">
                            <p class="text-justify text-hyphenate">
                                Durch Zwei-Faktor-Authentifizierung wird die Sicherheit Ihres Benutzerkontos verbessert.
                                Verwenden Sie auf Ihrem Mobilgerät (Handy/Tablet) eine Authentifizierungs-App, um einen Sicherheitscode zu erstellen,
                                der bei der Anmeldung neben Ihren bekannten Zugangsdaten angefordert wird.
                            </p>
                            <ol class="text-justify text-hyphenate pl-4">
                                <li>
                                    <p>
                                        Öffnen Sie ihre Authentifizierungs-App.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Fügen Sie in der App ein Konto hinzu und scannen Sie den unten stehenden QR-Code.
                                    </p>
                                    <v-row class="mb-3">
                                        <v-col cols="12" sm="auto" class="text-center">
                                            <qrcode-vue :value="otpauthLink" size="200" level="H" />
                                        </v-col>
                                        <v-col>
                                            <p>
                                                Sollten Sie den QR-Code nicht scannen können, dann geben Sie 
                                                in der App bitte folgenden Schlüssel ein:
                                            </p>
                                            <code class="d-inline-flex font-weight-bold align-center text-left pa-0">
                                                <span class="ml-2 mr-1 py-1">
                                                    {{ vBenutzerDaten.secret2FA.replace(/(.{4})/g, '$1 ').trim() }}
                                                </span>
                                                <v-btn color="black" type="button"
                                                       @click.stop.prevent="shareSecret2FA"
                                                       icon tile text>

                                                    <v-icon>mdi-content-copy</v-icon>
                                                </v-btn>
                                                <a id="otpauthLinkOpener" :href="otpauthLink" class="d-none" />
                                            </code>
                                            <p v-show="copyClipboardSuccess" class="text-body-2 red--text">
                                                Schlüssel in Zwischenablage kopiert
                                            </p>
                                        </v-col>
                                    </v-row>
                                </li>
                                <li>
                                    <p>
                                        Nachdem das Konto eingerichtet ist, geben Sie bitte den von der App erstellten Code ein:
                                    </p>
                                    <verify-otp :secret="vBenutzerDaten.secret2FA" class="d-inline-flex"
                                                @verifyOtpDone="(rst) => { this.isOtpValid = true; }" />
                                </li>
                            </ol>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn color="primary" type="submit"
                                    :disabled="!isOtpValid || isLoading" :loading="isLoading"
                                    @click.stop.prevent="doSend">
                                Senden
                            </v-btn>
                            <v-btn color="primary" type="button"
                                    @click.stop.prevent="show2FA = false">
                                Zurück
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <!-- Verifizierung der Email-Adresse -->
                <v-card v-if="verCode">
                    <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                        Verifizierung Ihrer Registrierung
                    </v-card-title>
                    <v-card-text class="text-body-1 text--primary">

                        <v-progress-linear v-if="isLoading" indeterminate />

                        <v-alert v-if="verificationSuccess && !verificationErrorText"
                                 class="mt-4 mb-0" type="success" outlined>
                            Vielen Dank! Die Verifizierung war erfolgreich. Sie können sich nun mit Ihrem 
                            Apotheken-IK und Ihrem im vorherigen Schritt gewählten Passwort anmelden.
                        </v-alert>

                        <v-alert v-if="!verificationSuccess && verificationErrorText" 
                                 class="mt-4 mb-0" type="error">
                            {{ verificationErrorText }}
                        </v-alert>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" to="/">
                            Zur Anmeldung
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
        </v-row>

        <!-- Dialog Erfolgreiche Registrierung -->
        <v-dialog v-model="showDialogSuccess">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.accountOk</v-icon>
                    Nur noch ein Schritt
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    Vielen Dank für Ihre Registrierung im Service-Portal für Apotheken!<br />
                    Sie erhalten in Kürze einen Link zur Bestätigung Ihrer Anmeldung an die von Ihnen angegebene Email-Adresse.
                    Der Link ist ab sofort 24 Stunden gültig. 
                    Bitte überprüfen Sie ggf. auch Ihren Spam-Ordner, falls die Email nicht innerhalb weniger Minuten ankommt.
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" type="button"
                           @click="showDialogSuccess = false; $router.push('Home');">
                    
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Fehler bei Registrierung -->
        <v-dialog v-model="showDialogFailure">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.error</v-icon>
                    Registrierung fehlgeschlagen
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <v-alert class="mt-4 mb-0" type="error">
                        {{ errorMessage }}
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" type="button"
                           @click="showDialogFailure = false; $router.push('/');">

                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Captcha fehlgeschlagen -->
        <CaptchaFailDialog :isVisible="showDialogCaptchaFailure" />

    </v-container>
</template>

<script>
    import CaptchaFailDialog from '@/components/CaptchaFailDialog.vue'
    import VerifyOtp from '@/components/VerifyOtp.vue'
    import QrcodeVue from 'qrcode.vue'
    import TeilnahmeAnfrage from '@/components/TeilnahmeAnfrage.vue'

    export default {
        name: 'Registrierung',

        components: {
            CaptchaFailDialog,
            VerifyOtp,
            QrcodeVue,
            TeilnahmeAnfrage
        },

        computed: {
            otpauthLink: function () {

                let label = this.vBenutzerDaten.leistungserbringer;
                let secret = this.vBenutzerDaten.secret2FA;
                let issuer = 'Service-Portal%20f%C3%BCr%20Apotheken';

                //z.B. Service-Portal für Apotheken (IK 123456789)
                return `otpauth://totp/IK%20${label}?secret=${secret}&issuer=${issuer}`;
            },

            isInputValid: function () {
                return this.verCode !== ""
                    && this.isValid
                    && this.allLicensesAgreed
                    && this.vBenutzerDaten.pw === this.vBenutzerDaten.pwWiederholung;
            },

            isRequestAuthCodeIKValid: function () {
                return this.requestAuthCodeIK.length == 9 && this.requestAuthCodePLZ.length == 5;
            },

            allLicensesAgreed: function () {
                return (this.licenseAgreement1 && this.licenseAgreement2) && ((this.licenseAgreement4 && this.licenseAgreement3) || (this.licenseAgreement4 && this.licenseAgreement3 && this.licenseAgreement5) || (!this.licenseAgreement4 && this.licenseAgreement5) )
            },
            guidMask() {
                return 'NNNNNNNN-NNNN-NNNN-NNNN-NNNNNNNNNNNN';
            }
        },

        watch: {
            'vBenutzerDaten.teilnahmeCode': function (guid) {
                if (!guid || guid.length < 36) {
                    this.regCodeErrors = [];
                    return;
                }

                this.fetchPreRegistrationData(guid);
            },
        },

        data() {
            return {
                showPasswort: false,
                pwTextRp: 'password',
                isValid: true,
                verificationErrorText: '',
                vBenutzerDaten: {
                    teilnahmeCode: '',
                    codeReadOnly: false,
                    email: '',
                    pw: '',
                    pwWiederholung: '',
                    secret2FA: '',
                    leistungserbringer: null,
                    apoName: '',
                },
                licenseAgreement1: false,
                licenseAgreement2: false,
                licenseAgreement3: false,
                licenseAgreement4: false,
                licenseAgreement5: false,
                emailRules: [
                    v => !!v || 'Bitte geben Sie eine gültige Email-Adresse ein',
                    v => /.+@.+\..+/.test(v) || 'Geben Sie bitte eine gültige Email-Adresse ein'
                ],
                passwordRules: [
                    v => !!v || 'Bitte geben Sie Ihr gewünschtes Passwort ein',
                    v => (v && v.length >= 10) || 'Das Passwort muss mindestens 10 Zeichen lang sein',
                    v => /(?=.*[A-Z])/.test(v) || 'Das Passwort muss mindestens einen Großbuchstaben beinhalten',
                    v => /(?=.*[a-z])/.test(v) || 'Das Passwort muss mindestens einen Kleinbuchstaben beinhalten',
                    v => /(?=.*\d)/.test(v) || 'Das Passwort muss mindestens eine Zahl beinhalten',
                    v => /([!@$%"&/\\()+=?#,.;*_-])/.test(v) || 'Das Passwort muss mindestens ein Sonderzeichen [!@$%"&/\\()+=?#,.;*_-] beinhalten'
                ],
                repeatpasswordRules: [
                    v => !!v || 'Bitte wiederholen Sie hier Ihr Passwort',
                    v => v == this.vBenutzerDaten.pw || 'Die beiden Passwörter müssen übereinstimmen'
                ],
                regCodeRules: [
                    v => !!v || 'Bitte geben Sie hier Ihren Teilnahme-Code ein',
                    v => (v && v.length == 36) || 'Ihre Eingabe ist unvollständig!'
                ],
                regCodeErrors: [],
                showDialogSuccess: false,
                showDialogFailure: false,
                showDialogCaptchaFailure: false,
                show2FA: false,
                isLoading: false,
                verCode: '',
                regCodeAvailable: false,
                isOtpValid: false,
                verificationSuccess: false,
                requestAuthCodeIK: '',
                requestAuthCodePLZ: '',
                errorMessage: '',
                copyClipboardSuccess: false,
                otpauthSchemeHandlerDetected: false,
            }
        },

        activated() {

            // set readonly when provided via query
            if (this.$route.query.regcode) {
                this.vBenutzerDaten.teilnahmeCode = this.$route.query.regcode;
                this.vBenutzerDaten.codeReadOnly = true;
            }

            this.verCode = this.$route.query.vercode;
            if (this.verCode) {
                this.verifyRegister();
            }
        },

        methods: {
            uppercase() {
                this.vBenutzerDaten.teilnahmeCode = this.vBenutzerDaten.teilnahmeCode.toUpperCase();
            },

            setRegCodeAvailable(value) {
                this.regCodeAvailable = value;
            },

            showErrorInDialog(err) {

                let msg = err.message;
                if (err.response && err.response.data && err.response.data.message)
                    msg = err.response.data.message;

                this.errorMessage = msg;
                this.showDialogFailure = true;
                console.log(err);
            },

            async verifyRegister() {
                try {
                    this.isLoading = true;

                    // register verification request in backend
                    let rsp = await this.$http.post('auth/verifyregister', { RegCode: this.verCode });
                    if (rsp && rsp.status === 200) {
                        this.verificationSuccess = true;
                        this.verificationErrorText = '';
                    }
                }
                catch (err) {
                    let msg = err.message;
                    if (err.response && err.response.data && err.response.data.message)
                        msg = err.response.data.message;

                    this.verificationErrorText = msg;
                }
                finally {
                    this.isLoading = false;
                }
            },

            async verifyCaptcha() {
                try {
                    // get captcha token
                    await this.$recaptchaLoaded();
                    let token = await this.$recaptcha('register');
                    let rsp = await this.$http.get("auth/verifycaptcha", { params: { token: token } });

                    if (rsp.status !== 200)
                        throw new Error('Ungültiges Captcha');

                    return true;
                }
                catch (err) {
                    this.showDialogCaptchaFailure = true;
                    return false;
                }
            },

            async register() {

                //licenseAgreement4 Beanstandungen
                //licenseAgreement5 Zuzahlungsprüfung
                //wenn beide Module abgewählt sind, dann die Speicherung abbrechen
                if (!this.licenseAgreement4 && !this.licenseAgreement5)
                    return;
                let vModule = "";
                if (this.licenseAgreement5)
                    vModule += "1";
                if (this.licenseAgreement4)
                    vModule += vModule !== "" ? "|2" : "2";

                let rsp = await this.$http.post('auth/register', {
                    Email: this.vBenutzerDaten.email,
                    RegCode: this.vBenutzerDaten.teilnahmeCode,
                    Password: this.vBenutzerDaten.pw,
                    Secret2FA: this.vBenutzerDaten.secret2FA,
                    Module: vModule
                });

                if (rsp && rsp.status === 200) {
                    this.showDialogSuccess = true;
                }
            },

            async doSend() {
                try {
                    this.isLoading = true;

                    // verifyCaptcha hat eigene Fehlerbehandlung mit separatem Dialog
                    let captchaOk = await this.verifyCaptcha();
                    if (captchaOk)
                        await this.register();
                }
                catch (err) {
                    this.showErrorInDialog(err);
                }
                finally {
                    this.isLoading = false;
                }
            },

            async fetchPreRegistrationData(guid) {
                try {
                    this.isLoading = true;

                    let rsp = await this.$http.get('auth/fetchPreRegistrationData', {
                        params: { guid: guid }
                    });

                    if (rsp && rsp.status === 200) {
                        this.vBenutzerDaten.secret2FA = rsp.data.secret2FA;
                        this.vBenutzerDaten.leistungserbringer = rsp.data.leistungserbringer;
                        this.vBenutzerDaten.apoName = rsp.data.apoName;
                        this.vBenutzerDaten.codeReadOnly = true;
                    }
                }
                catch (err) {
                    this.regCodeErrors = [
                        err.response.data.message + ' Bitte korrigieren Sie Ihre Eingabe!'
                    ]
                }
                finally {
                    this.isLoading = false;
                }
            },

            async shareSecret2FA() {

                let timeout = 1000;
                let ctx = this;

                let copyToClipboard = function () {
                    navigator.clipboard.writeText(ctx.vBenutzerDaten.secret2FA);
                    ctx.copyClipboardSuccess = true;
                };

                let onBlur = function () {
                    ctx.otpauthSchemeHandlerDetected = true;
                };

                let onVisibilityChange = function (e) {
                    if (e.target.visibilityState === 'hidden') {
                        ctx.otpauthSchemeHandlerDetected = true;
                    }
                };

                if (!ctx.otpauthSchemeHandlerDetected) {

                    window.addEventListener('blur', onBlur);
                    document.addEventListener('visibilitychange', onVisibilityChange);

                    setTimeout(function () {

                        if (!ctx.otpauthSchemeHandlerDetected) {
                            copyToClipboard();
                        }

                        window.removeEventListener('blur', onBlur);
                        document.removeEventListener('visibilitychange', onVisibilityChange);

                    }, timeout);
                }

                ctx.copyClipboardSuccess = false;
                document.getElementById('otpauthLinkOpener').click();
            },
        }
    }
</script>