<template>
    <v-container fluid class="pa-0">

        <!-- Hauptauswahl: Teilnahme-Code vorhanden? -->
        <v-row class="justify-center">
            <v-col cols="12" sm="8" md="6" lg="4">
                
                <v-card>
                    <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                        <span>Registrierung:&nbsp;</span>
                        <span>So funktioniert es!</span>
                    </v-card-title>
                    <v-card-text class="text-body-1 text--primary">
                        <p class="text-justify text-hyphenate">
                            Vielen Dank, dass Sie sich für die Teilnahme am Service-Portal für Apotheken registrieren möchten.
                            Sie benötigen einen Teilnahme-Code / QR-Code, den wir Ihnen gerne per Post zusenden oder dies bereits im Rahmen der
                            Beanstandungen getan haben.
                        </p>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="font-weight-semibold">
                                    Ich habe einen Teilnahme-Code
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="text-justify text-hyphenate">
                                        Wenn Sie den Brief mit dem Teilnahme-Code / QR-Code bereits vorliegen haben, können Sie den
                                        Code nun einscannen oder auf "Registrierung beginnen" klicken und den Code dort eingeben.
                                    </p>
                                    <v-row class="justify-end mt-4" no-gutters>
                                        <v-col cols="auto">
                                            <v-btn color="primary" type="button" class="v-btn-multiline"
                                                   @click="$emit('setAvailable', regCodeAvailable = true)">

                                                Registrierung beginnen
                                            </v-btn>                                          
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="font-weight-semibold">
                                    Ich möchte einen Teilnahme-Code anfordern
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="text-justify text-hyphenate">
                                        <strong>
                                            Wir senden Ihnen gerne einen Teilnahme-Code an die für
                                            <u>
                                                Ihr IK bei der IK-Vergabestelle hinterlegte Adresse.
                                            </u>
                                        </strong>
                                        <br />Bitte geben Sie Ihr Institutionskennzeichen (IK) und die Postleitzahl (PLZ)
                                        Ihrer Apotheke ein und klicken Sie auf "Code anfordern".
                                    </p>
                                    <v-row class="mt-4" dense>
                                        <v-col cols="12" sm="6">
                                            <v-text-field label="IK" v-model="requestAuthCodeIK"
                                                          autocomplete="off" :error-messages="IKerrorMessage"
                                                          maxlength="9" dense clearable />

                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field label="PLZ" v-model="requestAuthCodePLZ"
                                                          autocomplete="postal-code" :error-messages="PLZerrorMessage"
                                                          :disabled="!isValidIK" maxlength="5" dense clearable />

                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field label="Straße" v-model="vStrasse"
                                                          autocomplete="off" dense disabled />

                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field label="Ort" v-model="vOrt"
                                                          autocomplete="off" dense disabled />

                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-select label="Anrede" v-model="benutzer.anrede"
                                                      :items="$store.state.anrede" item-text="bezeichnung" item-value="anrede"
                                                      :menu-props="{ maxHeight: '400', nudgeBottom: '30px' }"
                                                      :class="{ 'custom-dense': $vuetify.breakpoint.mdAndDown }" dense clearable 
                                                      :disabled="!isValidPLZ"
                                                      :rules="eingabeRules.anrede"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="8" md="6">
                                            <v-text-field label="Nachname" v-model="benutzer.nachname"
                                                          autocomplete="off"
                                                          dense clearable :disabled="!isValidPLZ"
                                                          :rules="eingabeRules.name"/>
                                        </v-col>
                                        <v-col cols="12" sm="8" md="6">
                                            <v-text-field label="Vorname" v-model="benutzer.vorname"
                                                          autocomplete="off"
                                                          dense clearable :disabled="!isValidPLZ"
                                                          :rules="eingabeRules.name"/>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="auto" class="mt-0 pt-0">
                                            Ihre Daten werden ausschließlich für die Bearbeitung Ihres Anliegens verwendet.
                                            Hier finden Sie unsere <router-link to="/datenschutz" target="_blank">
                                                Datenschutzhinweise.
                                            </router-link>
                                        </v-col>
                                    </v-row>
                                    <v-row class="justify-end mt-4" no-gutters>
                                        <v-col cols="auto">
                                            <v-btn color="primary" type="button"
                                                   @click.stop.prevent="resetFields">

                                                Zurücksetzen
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn color="primary" type="submit" class="ml-2"
                                                   :disabled="!isRequestAuthCodeIKValid || waitingForCaptcha"
                                                   @click.stop.prevent="memberCoderequest">
                                                Code anfordern
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-alert v-if="verificationErrorText != ''" type="error">
                            {{ verificationErrorText }}
                        </v-alert>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text color="primary" to="/">
                            Zurück zur Anmeldung
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
        </v-row>

        <!-- Dialog Erfolgreiche Bestellung eines Teilnahme-Codes -->
        <v-dialog v-model="showDialogRequestAuthCodeSuccess" persistent>
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.email</v-icon>
                    Vielen Dank!
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    Sie erhalten einen Ausdruck mit Ihrem Teilnahme-Code 
                    an die für das IK {{ requestAuthCodeIK }} hinterlegte Postadresse.
                    Der Ausdruck wird Ihnen in den nächsten Tagen postalisch zugestellt.
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" type="button" @click="close">                  
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog Captcha fehlgeschlagen -->
        <CaptchaFailDialog :isVisible="showDialogFailure" />

    </v-container>
</template>

<script>
    import CaptchaFailDialog from '@/components/CaptchaFailDialog.vue'

    export default {
        name: 'TeilnahmeAnfrage',

        components: {
            CaptchaFailDialog,
        },

        data() {
            return {
                isValidIK: false,
                isValidPLZ: false,
                //vAdresse: '',
                vOrt: '',
                vStrasse: '',
                isValid: true,
                verificationErrorText: '',

                showDialogFailure: false,
                showDialogRequestAuthCodeSuccess: false,
                active: false,
                waitingForCaptcha: false,
                regCodeAvailable: false,
                requestAuthCodeIK: '',
                requestAuthCodePLZ: '',
                IKerrorMessage: '',
                PLZerrorMessage: '',
                showTooltip: false,
                benutzer: {
                    anrede: '',
                    nachname: '',
                    vorname: '',
                },
                anrede:[
                //    { Anrede: 1, Bezeichnung: 'Frau' },
                //    { Anrede: 2, Bezeichnung: 'Herr' },
                //    { Anrede: 3, Bezeichnung: 'Divers' },
                ],
                eingabeRules: {
                    name: [
                        v => !!v || 'Eingabe ist erforderlich',
                        v => v && !!v.trim() || 'Eingabe darf kein Leezeichen sein',

                    ],
                    anrede: [
                        v => !!v || 'Eingabe ist erforderlich'

                    ]
                }    ,
            }
        },
        //mounted() {
        //    this.getAnrede();
        //},

        computed: {
            isRequestAuthCodeIKValid: function () {
                return this.isValidIK && this.isValidPLZ && this.benutzer.vorname && this.benutzer.nachname;
            },

            
        },

        watch: {
            requestAuthCodeIK(value) {
                this.waitingForCaptcha = true;
                this.requestAuthCodeIK = value;
                this.IKerrorMessage = '';
                this.isValidIK = false;
                if (value.length === 9 && (!isNaN(Number(value)))) {
                    this.$http.post('kontakt/check', {
                        IK: parseInt(value),
                        PLZ: null

                    }).then((response) => {
                        if (response.status === 200) {
                            if (response.data[0] === 0) {
                            }
                            else
                                console.log(response.data[0]);
                            this.showTooltip = false;
                            this.waitingForCaptcha = false;
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.IKerrorMessage = '';
                            let errorMsg = error.response.data.error;

                            //Wenn die Fehlermeldung mit 1 anfängt, dann wird der Fehler nicht angezeigt.
                            let i = errorMsg.substring(0, errorMsg.indexOf(","));
                            let r = parseInt(i);

                            if (typeof (r) === 'number' && r == 1) {
                                this.isValidIK = true;
                                this.IKerrorMessage = '';
                            }
                            else {
                                this.isValidIK = false;
                                this.IKerrorMessage = [errorMsg];
                            }
                            console.log(error.response.data.error);
                            this.showTooltip = false; // TODO dialog
                            this.waitingForCaptcha = false;
                        }
                    });

                } else if ((isNaN(Number(value)))) { //&& value >= 300000000 && value <= 309999999)) {

                    this.IKerrorMessage = [
                         'Geben Sie bitte eine gültige IK ein!',
                    ]
                    
                } else {
                    return true;
                }
            },

            requestAuthCodePLZ(value) {

                this.isValidPLZ = false;
                //this.vAdresse = "";
                this.vOrt = "";
                this.vStrasse = "";
                this.showTooltip = false;
                this.requestAuthCodePLZ = value;
                this.PLZerrorMessage = '';
                this.waitingForCaptcha = true;
                if (this.isValidIK && value.length === 5 && (!isNaN(Number(value)))) {
                    this.$http.post('kontakt/check', {
                        IK: parseInt(this.requestAuthCodeIK),  //parseInt(value),
                        PLZ: this.requestAuthCodePLZ

                    }).then((response) => {
                        if (response.status === 200) {

                            let result = response.data[0];
                            this.isValidPLZ = true;
                            //this.vAdresse = "Der Teilnahme-Code wird an folgende Adresse gesendet:\n";
                            /*this.vAdresse = result.plz + " " + result.ort + " " + result.strasse;  //+ "\n" +;*/
                            this.vOrt = result.ort;
                            this.vStrasse = result.strasse;
                            this.showTooltip = true;
                            this.waitingForCaptcha = false;
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.PLZerrorMessage = [error.response.data.error];
                            this.showTooltip = false;
                            //console.log(error.response.data.error); 
                            this.waitingForCaptcha = false;
                        }
                    });
                }
                else if ((isNaN(Number(value)))) {

                    this.PLZerrorMessage = [
                        'Geben Sie bitte eine gültige IK ein!',
                    ]
                    
                } else {
                    return true;
                }
            },
        },

        methods: {

            //async getAnrede() {

            //    await this.$http.get('def/anrede').then((response) => {
            //        if (response.status === 200) {
            //            this.anrede = response.data;
            //        }
            //    });
                
            //},

            async memberCoderequest() {
                this.waitingForCaptcha = true;

                // register verification request in backend
                this.$http.post('kontakt/membercoderequest/' +
                    this.requestAuthCodeIK + "/" + this.benutzer.anrede + "/" + this.benutzer.nachname + "/" + this.benutzer.vorname
                ).then((response) => {
                    if (response.status === 200) {
                        this.showDialogRequestAuthCodeSuccess = true;
                        this.verificationErrorText = "";
                        this.waitingForCaptcha = false;
                    }
                }).catch(error => {
                    if (error.response) {
                        this.verificationErrorText = error.response.data.message;
                        this.waitingForCaptcha = false;
                    }
                });
            },

            close() {
                this.showDialogRequestAuthCodeSuccess = false;
                this.requestAuthCodeIK = "";
                this.requestAuthCodePLZ = "";
                this.verificationErrorText = "";
                this.$router.push('Home');
            },

            resetFields() {
                this.requestAuthCodeIK = "";
                this.requestAuthCodePLZ = "";
                this.verificationErrorText = "";
                this.vOrt = "";
                this.vStrasse = "";
            }

        }
    }
</script>